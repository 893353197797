<template>
    <div class="offcial">
          <iframe :src="url" style="width:100%;height:100vh" frameborder="0"></iframe> 
    </div>
</template>
<script>
export default {
    name:'offcial',
    data(){
        return{
            url:'https://www.xiaoe-tech.com/'
        }
    },
    
}
</script>